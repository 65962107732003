<template>
  <div
    class="d-flex align-items-center justify-content-between mb-4 gap-2"
  >
    <material-input
      v-model="option.answer"
      :label="`Antwoord ${optionNumber}`"
      variant="static"
    />

    <material-button
      class="row-button"
      color="danger"
      @click.prevent="$emit('deleted', option.id)"
    >
      <i
        class="material-icons-round"
      >delete</i>
    </material-button>
  </div>
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

export default {
  components: {
    MaterialButton,
    MaterialInput
  },

  props: {
    initialOption: {
      type: Object,
      required: true
    },
    optionNumber: {
      type: Number,
      required: true
    }
  },

  emits: [
    'changed',
    'deleted'
  ],

  data() {
    return {
      currentOption: undefined
    };
  },

  computed: {
    option: {
      get() { return this.currentOption || this.initialOption; },
      set(value) { 
        this.currentOption = { ...this.option, answer: value };
        this.$emit(
          'changed',
          this.currentOption
        );
       }
    }
  }
}
</script>