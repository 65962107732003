<template>
  <div class="d-flex gap-2 mb-2">
    <material-input
      v-model="newOptionInput"
      label="Antwoord toevoegen"
      variant="static"
      @keydown="onNewOptionInputKeyDown"
    />

    <material-button
      class="row-button"
      @click.prevent="onAddOptionButtonClicked"
    >
      <i class="material-icons-round">add</i>
    </material-button>
  </div>

  <poll-answer 
    v-for="option in filteredOptions"
    :key="option.id"
    :initial-option="option"
    :option-number="options.indexOf(option) + 1"
    @change="onOptionUpdated"
    @deleted="onOptionDeleted"
  />
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

import PollAnswer from '@/components/Poll/New/PollAnswer.vue';

export default {
  components: {
    MaterialButton,
    MaterialInput,
    PollAnswer
  },

  emits: [
    'update:modelValue'
  ],

  data() { 
    return {
      options: [],
      newOptionInput: '',
      key: 0
    };
  },

  computed: {
    filteredOptions() {
      return this.options.filter(o => !o.isDeleted)
    }
  },

  methods: {
    onAddOptionButtonClicked() {
      this.options = [ ...this.options, { id: this.key, answer: this.newOptionInput } ];
      this.newOptionInput = '';
      this.key += 0;
    },

    onNewOptionInputKeyDown(e) {
      if (e.key !== 'Enter') return;
      
      this.onAddOptionButtonClicked();
    },

    onOptionUpdated(option) {
      const index = this.options.findIndex(o => o.id === option.id);
      if (index < 0) return;

      this.options[index] = option;
    },

    onOptionDeleted(id) {
      const index = this.options.findIndex(o => o.id === id);
      if (index < 0) return;

      this.options[index] = { ...this.options[index], isDeleted: true };

    }
  }
}
</script>

<style>
.row-button {
  width: 40px;
  height: 40px;
  padding-inline: 0;
}
</style>